<div class="modal-header">
    <h4 class="modal-title">{{'login-page.FORGOT-PASSWORD' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closemodal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- Forgot Password Form -->
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword(forgotPasswordForm.value)">
      <div class="input-container">
        <input 
            [placeholder]="'login-page.EMAIL-ADDRESS' | translate" 
            class="input-field" 
            type="text" 
            formControlName="Username">
        <label for="input-field" class="input-label">{{'login-page.EMAIL-ADDRESS' | translate}}</label>
        <span class="input-highlight"></span>
    </div>
      <button type="submit" class="forget-password">{{'login-page.SENT-MY-PASSWORD' | translate}}</button>
    </form>
  </div>