import { Component } from '@angular/core';
import { Product } from '../../modules/interfaces';
import { LOCAL_STORAGE } from '../../modules/constants';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})

export class CartComponent {
    couponCheckTriggered: boolean=false;
    couponDoesNotExist: boolean=false;
    couponDoesNotApply: boolean=false;
    couponLimitReached: boolean=false;
    appliedCoupon: any;
    totalPrice: number=0;
    totalDiscount: number=0;

    constructor(
        private http: HttpClient, 
        private translateService:TranslateService, 
        private cartService:CartService, 
        private router: Router,
        private cookieService: CookieService,
        private cmsService: LozztoysCmsService,
        private conomeService: ConomeECommerceService,
        private userProfileService: UserProfileService,

    ) { }

    products: any[] = [];
    userId: string = '';
    qty = 1;
    totalprice = 0;
    totalPriceCoupon = 0;
    breadCrumbItems!: any;
    conomeUrl = environment.conomeUrl;
    companyId: number = 0;
    productsFromConome: any;
    productsFromCartIds: any;
    productsFromCart: any;
    goToShopButton: any;
    couponCode:string='';
    productDetailIds: any[] = [];
    shippingCost : number = 0;

    ngOnInit() {
        this.cartService.cartProducts$.subscribe(products => {
            this.products = products;
            this.productDetailIds = this.products.map(product => ({
                ProductId: product.productDetailId,
                ProductQt:product.quantity
            }));
            this.calcTotal();
          });

        this.userProfileService.userId$.subscribe(userId => {
            this.userId = userId;
        });
        this.getProducts();
        this.translateBreadcrumbItems(); 
        this.getCartPageFromCMS();

        this.translateService.onLangChange.subscribe(() => {
            this.translateBreadcrumbItems();
            this.getCartPageFromCMS();
        });
    }

    removeCoupon() {
        this.appliedCoupon = null;
        localStorage.removeItem('couponCode');
        this.couponCode = '';
        this.totalDiscount=0;
        this.calcTotal();
        this.calcCouponTotalOnInit();
    }
    
    limitNumberLength(event: any, maxLength: number) {
        const input = event.target;      
        if (input.value > maxLength) input.value = maxLength
    }


    calculateShippingForProducts(totalPrice: number): Observable<number> {
        const companyId = Number(this.cookieService.get('companyID'));
        return this.conomeService.getShippingForProduct(companyId, this.productDetailIds, totalPrice);
    }
    
    calcTotal() {
        let totalPrice = 0;
        this.products.forEach((el: any) => {
            el['total'] = el.productDetails[0].sellingPriceWithRebate * el.quantity;
            totalPrice += el['total'];
        });
        
        this.totalprice = totalPrice;
        
        this.calculateShippingForProducts(this.totalprice).subscribe({
            next: (shippingCost) => {
                this.shippingCost = shippingCost;
    
                this.totalPriceCoupon = this.totalprice + this.shippingCost;
                
                this.cartService.calculateTotalPriceForCheckout(this.products, this.shippingCost);
                this.cartService.setTotal(this.totalprice + this.shippingCost);
            },
            error: (err) => {
                console.error(err, 'Error fetching shipping cost');
            }
        });
    }

    getProducts() {
        this.cartService.fetchCartData(this.userId).subscribe({
          next: (products: any[]) => {
            this.products = products;        
            this.calcTotal();
        },
    });
      }
    
    calcTotalOnChange(i: number, ev: Event) {
        const newQty = Number((ev.target as HTMLInputElement).value);
        const product = this.products[i]; 
        if (newQty < 1) {
            return;
        }
        if (product.quantity !== newQty) {
            product.quantity = newQty;
            product.total = product.price * newQty; 

            if (this.userId) {
                this.cartService.updateCartItemInCMS(product, this.userId);
            } else {
                this.cartService.updateCartInCookies(product);
            }
            // this.cartService.updateCartProducts(this.products);
            this.calcTotal();
            this.calcCouponTotalOnInit()
        }
    }

    removeItemFromCart(id: any, productDetailID: number, i: number) {        
        const productToRemove = this.products[i];
    
        this.totalprice -= productToRemove.total;
        this.products = this.products.filter(p => !(p.id === id && p.productDetails[0]?.id === productDetailID));
        if (this.userId) {
            this.cmsService.getCartByUser(this.userId).subscribe({
                next: (response: any) => {
                    const existingCart = response?.data || [];
                    const cartItemToRemove = existingCart.find(
                        (item: any) => 
                            item.attributes.productId === id &&
                            parseInt(item.attributes.productDetailId) === productDetailID
                    );
    
                    if (cartItemToRemove) {
                        const cartEntryId = cartItemToRemove.id;
                        this.cmsService.removeProductFromCartById(cartEntryId).subscribe({
                            next: () => {
                                this.cartService.updateCartProducts(this.products);
                            }
                        });
                    }
                }
            });
        } else {
            this.cartService.removeProductFromCookies(id, productDetailID);
            this.cartService.updateCartProducts(this.products);
        }
        this.calcCouponTotalOnInit();
    }

    get total() {
        return this.products.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), 0);
    }
    get currentLanguage(){
        return this.translateService.currentLang
    }

    get numberOfProducts(){
        return JSON.parse(this.cookieService.get(LOCAL_STORAGE.PRODUCTS) || "[]").length
    }

    //is used for banner data
    private async translateBreadcrumbItems() {
        const homeLabel = await this.translateService.get("menu.HOME").toPromise();
        const firstLabel = await this.translateService.get("menu.CART").toPromise();

        this.breadCrumbItems = [
            { label: homeLabel, link: '/home' },
            { label: firstLabel, link: '/cart' },
        ];
    }

    goToCheckoutPage(){
        this.router.navigate(['/checkout'])
    }

    returnToShopPage(link: string){
        if(link){
            this.router.navigate([link])
        }
    }

    getCartPageFromCMS() {
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getCartPage(populateAndLocale).subscribe({
            next: (res) => {
                this.goToShopButton = res.data.attributes.goToShopButton;      
            }
        })    
    }

    calcCouponTotalOnInit() {
        this.couponCheckTriggered = true;
        this.couponDoesNotExist = false;
        this.couponDoesNotApply = false;
        this.couponLimitReached = false;
      
        if (!this.couponCode) {
            this.calcTotal();
            return;
        }
    
        this.cartService.getCoupons().subscribe(
          (couponsResponse) => {
            const coupons = couponsResponse.data.filter((coupon: any) => coupon.attributes.isActive !== false);
            let totalPrice = 0;
            let couponFound = false;
            let couponApplied = false;
            let totalDiscount = 0;
    
            this.products.forEach((product: any) => {
              let productTotal = product.total;
              coupons.forEach((coupon: any) => {
                if (coupon.attributes.CouponCode === this.couponCode) {
                  this.appliedCoupon = coupon.attributes.CouponCode;
                  localStorage.setItem('couponCode', this.appliedCoupon);
                  couponFound = true;
                  const productsArray = coupon.attributes.products.data;
                  const categoriesArray = coupon.attributes.categories.data;
                  const couponLimit = coupon.attributes.Limit || Infinity;
                  const timesUsed = coupon.attributes.TimesUsed || 0;
    
                  if (timesUsed < couponLimit) {
                    const appliesToAllProducts = productsArray.length === 0 && categoriesArray.length === 0;
                    const appliesToProduct = productsArray.some((p: any) => p.attributes.productID === product.id);
                    const appliesToCategory = categoriesArray.some((c: any) => Number(c.attributes.category_id) === product.categoryId);
                    
                    if (appliesToAllProducts || appliesToProduct || appliesToCategory) {
                      couponApplied = true;
                      const discountAmount = product.total * (coupon.attributes.Discount / 100);
                      productTotal -= discountAmount;
                      totalDiscount += discountAmount;
                    }
                  } else {
                    this.calcTotal();
                    this.couponLimitReached = true;
                  }
                }
              });
              totalPrice += productTotal;
            });
    
            this.totalPriceCoupon = totalPrice + this.shippingCost;
            this.totalDiscount = totalDiscount;
    
            if (!couponFound || !couponApplied) {
                localStorage.removeItem('couponCode');
                this.appliedCoupon = null;
            }
    
            if (!couponFound) {
              this.couponDoesNotExist = true;
              this.calcTotal();
            }
    
            if (couponFound && !couponApplied && !this.couponLimitReached) {
              this.couponDoesNotApply = true;
              this.calcTotal();
            }
          },
          (error) => {
            console.error('Error fetching coupons:', error);
          }
        );
    }    
}
