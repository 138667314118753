<app-banner title="{{ 'menu.ABOUT-US' | translate }}" [breadcrumbItems]="breadCrumbItems"></app-banner>

<div class="container">
    <div class="contentLeft">
      <div class="row img-container">
        @for (item of aboutUs?.images?.data; track $index) {
          <div class="imgWrapper">
            <img [src]="cmsUrl + item.attributes.url" alt="image">
         </div>
        }
      </div>
    </div>
    <div class="contentRight">
      <div class="content">
        <h4>{{aboutUs.sectionTitle}}</h4>
        <h2>{{aboutUs.sectionSubtitle}}</h2>
        <p>{{aboutUs.paragraph}}</p>
        <button class="read-more-button">Read more... </button>
      </div>
    </div>
  </div>