import { Component, EventEmitter, Inject, Output, PLATFORM_ID } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../services/login/login.service';
import { LoginResponse } from '../../modules/interfaces';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '../../modules/constants';
import { timer } from 'rxjs';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { Product } from '../../modules/interfaces';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
    signupForm: FormGroup;
    signInForm = new FormGroup({

        Username : new FormControl(''),
        password : new FormControl(''),
    });

    emailExists = false;
    usernameExists = false;
    registrationSucces = false;
    defaultRoleId = -1;
    errorMessage!: string;
    formData!: UntypedFormGroup;
    signupPassfield!: boolean;
    fieldTextType: any;
    submitted = false;
    signupsubmit = false;
    labelInfo!: string;
    labelError!: string;
    isFormValid = false;
    @Output() isUserLoggedInChange: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() userData: EventEmitter<any> =
        new EventEmitter<any>();
    isUserLoggedIn = false;
    resetPassword = false;
    emailToResetPassword = '';
    sendEmailResult = "";

    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private loginService: LoginService,
        private router: Router,
        private userService: UserProfileService,
        private http: HttpClient,
        private translate: TranslateService,
        private cartService:CartService,
        private cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: Object

    ) {
        this.signupForm = this.formBuilder.group({
            firstname: ['', [Validators.required, Validators.required]],
            lastname: ['', [Validators.required, Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            PlaceId : new FormControl(''),
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    this.passwordMatchValidator,
                ],
            ],
            confirmPassword: ['', [Validators.required]],
            Address : new FormControl(''),
            AddressNumber : new FormControl('',[Validators.required]),
            BankName : new FormControl(''),
            IBAN : new FormControl(''),
            SWIFT : new FormControl(''),
            BankAccount : new FormControl(''),
            phoneNumber: new FormControl('')
        });

        this.signupForm.valueChanges.subscribe((value) => {
            if (this.signupForm.valid) {
                this.isFormValid =
                    this.signupForm.get('password')?.value ===
                    this.signupForm.get('confirmPassword')?.value;
            } else {
                this.isFormValid = false;
            }
        });
    }

    validateUsername(control: AbstractControl): ValidationErrors | null {
        const username = control.value;
        const usernameRegex = /^[a-z0-9_-]+$/;

        if (!username) {
            return null;
        }
        if (!username.match(usernameRegex)) {
            return { invalidUsername: true };
        }
        return null;
    }

    passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
        const password = control.get('password')?.value;
        const confirmPassword = control.get('confirmPassword')?.value;
        const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]+$/;

        if (password !== confirmPassword) return { passwordMatch: true };
        if (!password) return null;

        if (password !== confirmPassword) return { passwordMatch: true };

        if (!password.match(passwordRegex)) return { invalidPassword: true };

        if (password !== confirmPassword) return { passwordMatch: true };

        return null;
    }

    onSubmit() {
        if(isPlatformBrowser(this.platformId)){

            localStorage.clear();
            const productsArray: Product[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PRODUCTS) || '[]');
            this.cartService.updateCartProducts(productsArray);
            if (this.signupForm.valid) {
                const signUpData = {
                    Name: this.signupForm.value.firstname,
                    Surname: this.signupForm.value.lastname,
                    Email: this.signupForm.value.email,
                    Password: this.signupForm.value.password,
                    confirmPassword: this.signupForm.value.confirmPassword,
                    Address: this.signupForm.value.Address,
                    AddressNumber: this.signupForm.value.AddressNumber,
                    PhoneNumber: this.signupForm.value.phoneNumber
                };

                this.userService.register(signUpData).subscribe(
                    (response) => {
                        this.errorMessage = '';
                        this.signupForm.reset();
                        this.registrationSucces = true;
                        timer(3000).subscribe(() => {
                            this.registrationSucces = false;
                        });
                    },
                    (error) => {
                        if (error.status === 400) {
                        // Display an error message to the user
                            this.errorMessage = 'sign-up.ERROR-MESSAGE';
                        }
                    }
                );
            }
        }
    }

    get form() {
        return this.formData.controls;
    }

    get signupform() {
        return this.signupForm.controls;
    }

    closemodal() {
        this.modalService.dismissAll();
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    togglesignupPassfield() {
        this.signupPassfield = !this.signupPassfield;
    }

    login(data:any) {
        localStorage.clear();
        const productsArray: Product[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PRODUCTS) || '[]');
        this.cartService.updateCartProducts(productsArray);
        this.labelError = 'error';
        this.labelInfo = 'info';
        this.userService
            .login(data)
            .subscribe(
                (response) => {
                    const httpResponse: LoginResponse =
                        response as LoginResponse;
                    this.isUserLoggedInChange.emit(true);
                    this.userData.emit(httpResponse.user);
                    this.closemodal();
                    this.cookieService.set(LOCAL_STORAGE.TOKEN, httpResponse.token);
                    this.cookieService.set("username", httpResponse.user.displayName);
                    this.cookieService.set('userID', httpResponse.id.toString());
                    /* localStorage.setItem(
                            LOCAL_STORAGE.TOKEN,
                            httpResponse.jwt
                        ); */
                    this.router.navigate(['home']);
                },
                (error) => {
                    // Login failed, handle the error (e.g., display an error message)
                    this.labelError = 'login-page.ERROR-MESSAGE';
                }
            );
    }

    ngOnInit(): void {
        // Validation
        this.formData = this.formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]],
        });
    }


    forgotPasswordForm = new FormGroup({

        Username : new FormControl(''),
        
    });

    forgotPassword(username:any) {
        const data = { ...username};
    
        this.userService.forgotpassword(data).subscribe({
            next: (res) => {
            },
            error: (error) => {
                console.error(error);
            },
        });
    }
}
