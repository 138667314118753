import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../../modules/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthComponent } from '../../modals/auth/auth.component';
import { AuthServiceService } from 'src/app/services/AuthServices/auth-service.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { Product } from 'src/app/modules/interfaces';
import { ChangeDetectorRef } from '@angular/core';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { ConomeECommerceService } from '../../services/conomeServices/conome-e-commerce.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

    urlParts: string[] = [];
    isUserLoggedIn = false;
    isUserModalOpen = false
    user: any;
    products: any;
    totalPrice: any;
    conomeUrl = environment.conomeUrl;
    currentLanguage = this.translateService.currentLang;
    menuItems: any[] = [];
    headerText: string = '';
    tetxAndLink: any;
    productsFromConome: any[] = []
    userId: string = ''
    productsFromCartIds: number[] = [];
    companyId: any;
    cartItems: number = 0
    searchText='';

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private router: Router,
        private translateService: TranslateService,
        private modalService: NgbModal,
        private userProfileService: UserProfileService,
        private authService: AuthServiceService,
        private cdRef: ChangeDetectorRef,
        private cookieService: CookieService,
        private cartService: CartService,
        private sharedService: DataSharedService,
        private cmsService: LozztoysCmsService,
        private conomeService: ConomeECommerceService,
        private route: ActivatedRoute,
    ) {
        //subscribes every changes of your route
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                //scroll to top
                if (isPlatformBrowser(this.platformId)) {
                    window.scrollTo(0, 0);
                }

            }
        });


        this.cartService.cartProducts$.subscribe((cartProducts: Product[]) => {
            this.products = cartProducts;
            this.numberOfProducts();
            this.updateTotalPrice();
        });

        this.route.queryParams.subscribe((params) => {
            if (params['clearNavbar']) {
              this.searchText='';
              this.router.navigate([], { queryParams: { clearNavbar: null }, queryParamsHandling: 'merge' });
            }
          });
    }

    searchProducts(){
        this.router.navigate(['/products'], { queryParams: { search: this.searchText } });
    }

    openModal() {
        const modalRef = this.modalService.open(AuthComponent, { size: 'md', centered: true });

        modalRef.componentInstance.isUserLoggedInChange.subscribe((loggedIn: boolean) => {
            this.isUserLoggedIn = loggedIn;
            modalRef.dismiss();
        });
        modalRef.componentInstance.userData.subscribe((userData: any) => {
            this.user = userData?.displayName;
        })
        this.updateTotalPrice();
        this.numberOfProducts();
        this.closeMenu();
    }

    closemodal() {
        this.modalService.dismissAll();
    }

    checkIfUserIsLoggedIn() {
        this.isUserLoggedIn = this.cookieService.get(LOCAL_STORAGE.TOKEN) ? true : false;
        this.user = this.cookieService.get("username");
        this.updateTotalPrice();
        this.numberOfProducts();

    }

    goToUserProfile() {
        this.router.navigate(['userprofile'])
    }

    logout() {
        this.cookieService.delete(LOCAL_STORAGE.TOKEN);
        this.cookieService.delete("username");
        this.cookieService.delete("userID");
        this.userProfileService.clearUserId();
        this.getProducts();

        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear()
            this.isUserLoggedIn = false
            this.router.navigateByUrl('/');
            this.user = 'Login / Register';
            const selectedLanguage = localStorage.getItem(LOCAL_STORAGE.SELECTED_LANGUAGE);

            if (selectedLanguage) {
                this.translateService.setDefaultLang(selectedLanguage);
                this.translateService.use(selectedLanguage);
            }
            else {
                this.translateService.setDefaultLang('sq');
                this.translateService.use('sq');
            }
            this.updateTotalPrice();
            this.numberOfProducts();
        }

    }

    removeCartItem(id: any, productDetailID: number, i: number) {
        this.products = this.products.filter((p: any) => {
            return !(p.id === id && p.productDetails[0]?.id === productDetailID);
        });
        const productToRemove = this.products[i];
        if (this.userId) {
            this.cmsService.getCartByUser(this.userId).subscribe({
                next: (response: any) => {
                    const existingCart = response?.data || [];
                    const cartItemToRemove = existingCart.find(
                        (item: any) => 
                            item.attributes.productId === id &&
                            parseInt(item.attributes.productDetailId) === productDetailID
                    );
                    if (cartItemToRemove) {
                        // Remove the cart entry by ID
                        const cartEntryId = cartItemToRemove.id;
                        this.cmsService.removeProductFromCartById(cartEntryId).subscribe({
                            next: () => {
                                this.cartService.updateCartProducts(this.products);
                                this.cartItems = this.products.length;
                                this.updateTotalPrice();
                                this.numberOfProducts();
                            }
                        });
                    }
                }
            });
        } else {
            this.cartService.removeProductFromCookies(id, productDetailID);
            this.cartService.updateCartProducts(this.products);
            this.cartItems = this.products.length;
            this.updateTotalPrice();
            this.numberOfProducts();
        }
    }

    get currentRoute() {
        if (isPlatformBrowser(this.platformId)) {
            this.urlParts = window.location.href.split("/");
            return this.urlParts[this.urlParts.length - 1];
        }
        return [];

    }

    changeLanguage(language: string) {
        if (isPlatformBrowser(this.platformId)) {
            this.translateService.use(language);
            localStorage.setItem(LOCAL_STORAGE.SELECTED_LANGUAGE, language);
        }

    }

    ngOnInit() {
        this.userProfileService.userId$.subscribe(userId => {
            this.userId = userId;
        });

        this.getProducts();

        this.translateService.onLangChange.subscribe((res) => {
            this.currentLanguage = res.lang;
            this.getMenuItems();
        });
        this.sharedService.isUserLoggedIn$.subscribe(status => {
            this.isUserLoggedIn = status;
        })
        this.checkIfUserIsLoggedIn()
        this.getUser();
        this.updateTotalPrice();
        this.numberOfProducts();
    }

    getProducts() {
        this.cartService.fetchCartData(this.userId).subscribe({
            next: (products: any[]) => {
                this.cartService.updateCartProducts(products)
                this.products = products;
                this.cartItems = products.length
                this.updateTotalPrice()
                this.numberOfProducts();
            },
            error: (err) => {
                console.error('Error fetching cart products:', err);
            }
        });
    }

    getUser() {
        const token = this.cookieService.get(LOCAL_STORAGE.TOKEN) || "";
        this.updateTotalPrice();
    }

    updateTotalPrice() {
        this.totalPrice = this.cartService.calculateTotalPrice(this.products);
        this.cdRef.markForCheck();
    }

    numberOfProducts() {
        this.cartItems = this.cartService.getNumberOfProducts(this.products);
    }

    closeMenu() {
        // Call this function when a link is clicked
        const collapsible = document.getElementById('navbarSupportedContent');
        if (collapsible) {
            collapsible.classList.remove('show'); // This will hide the menu
        }
    }


    getMenuItems() {
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getMenuItems(populateAndLocale).subscribe({
            next: (res) => {
                const response = res.data.attributes
                this.menuItems = response.menuItems.map((item: any) => {
                    return {
                        id: item.id,
                        title: item.title,
                        redirectLink: item.redirectLink
                    }
                })
                this.headerText = response.headerText;
                this.tetxAndLink = response.textAndLink
            },
            error: (err) => {
                console.error(err);
            }
        })
    }
}
