<app-banner title="{{ 'menu.CHECKOUT' | translate }}" [breadcrumbItems]="breadCrumbItems"></app-banner>
<div class="container pt-4 pb-3 py-sm-4">
    <ul class="nav nav-tabs justify-content-center w-100">
        <li class="nav-item flex-fill text-center">
            <a class="nav-link" (click)="goToCartPage()">
            {{ 'menu.CART' | translate }}
            </a>
        </li>
        <li class="nav-item flex-fill text-center">
            <a class="nav-link active">
            {{ 'menu.CHECKOUT' | translate }}
            </a>
        </li>
    </ul>
  <div class="rounded-3 shadow-lg mt-4 mb-5">
      <form (ngSubmit)="completeorder()" #myForm [formGroup]="formData" class="needs-validation px-3 px-sm-4 px-xl-5 pt-sm-1 pb-4 pb-sm-5">
          <div class="row pb-4 pt-3">
              <div class="col-sm-4 mb-4" [ngClass]="!token?'col-sm-4':'col-sm-6'">
                  <label class="form-label" for="fd-name">{{"checkout.NAME" | translate}}<sup class="text-danger ms-1">*</sup></label>
                  <input class="form-control" type="text" required id="fd-name" [(ngModel)]="name" formControlName="name" 
                    value="{{user?.surname ? user?.name + ' ' + user?.surname : user?.name}}"
                      [ngClass]="{ 'is-invalid': form['name'].touched && form['name'].errors }">
                  <div class="invalid-feedback">{{"checkout.ENTER-NAME" | translate}}</div>
              </div>
              <div class="col-sm-4 mb-4" [ngClass]="!token?'col-sm-4':'col-sm-6'">
                  <label class="form-label" for="fd-phone">{{"user-profile.PHONE-NUMBER" | translate}}<sup class="text-danger ms-1">*</sup></label>
                  <input value="{{user?.phoneNumber}}" class="form-control" type="text" [(ngModel)]="phoneNumber" required id="fd-phone" formControlName="phone"
                      [ngClass]="{ 'is-invalid': submitted && form['phone'].errors }">
                  <div class="invalid-feedback">{{"checkout.ENTER-PHONE" | translate}}</div>
              </div>
              <div class="col-sm-4 mb-4" *ngIf="!token">
                <label class="form-label" for="fd-email">{{"login-page.EMAIL-ADDRESS" | translate}}<sup class="text-danger ms-1">*</sup></label>
                <input value="{{user?.email}}" class="form-control" type="email" [(ngModel)]="email" id="fd-email" formControlName="email"
                    [ngClass]="{ 'is-invalid': submitted && form['email'].errors }">
                <div class="invalid-feedback">{{"checkout.ENTER-EMAIL" | translate}}</div>
              </div>
            </div>
          <h2 class="h5 pb-3">{{"user-profile.SHIPPING-ADDRESS" | translate}}</h2>
          <div class="row mb-4">
              <div class="col-sm-6 mb-4">
                  <label class="form-label" for="fd-city" >{{"user-profile.CITY" | translate}}<sup class="text-danger ms-1">*</sup></label>
                  <input value="{{user?.address}}" class="form-control" type="text" [(ngModel)]="city" required id="fd-city" formControlName="city"
                  [ngClass]="{ 'is-invalid': submitted && form['city'].errors }">
                  <div class="invalid-feedback">{{"checkout.ENTER-CITY" | translate}}</div>
              </div>
              <div class="col-sm-6 mb-4">
                  <label class="form-label" for="fd-address">{{"user-profile.ADDRESS" | translate}}<sup class="text-danger ms-1">*</sup></label>
                  <input class="form-control" type="text" [(ngModel)]="address" placeholder="{{'checkout.STREET-APARTMENT-SUITE' | translate}}" required
                      value="{{user?.address}}" 
                      id="fd-address" formControlName="address"
                      [ngClass]="{ 'is-invalid': submitted && form['address'].errors }">
                  <div class="invalid-feedback">{{"checkout.ENTER-ADDRESS" | translate}}</div>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-6 mt-10 mb-4 mb-sm-0">
                <div class="row">
                    <!-- <div class="col-sm-4 pt-3">
                        <button [disabled]="!isFormValid || totalPrice<=0" (click)="payCard()" class="btn btn-primary d-block w-100"><i class="ci-card"></i> {{"checkout.PAY-CARD" | translate}}</button>
                    </div>
                    <div class="col-sm-4 pt-3">
                        <button [disabled]="!isFormValid || totalPrice<=0" (click)="payCash()" class="btn btn-primary d-block w-100"><i class="ci-cashier"></i> {{"checkout.PAY-CASH" | translate}}</button>
                    </div> -->
                    <div class="col-sm-6 pt-3">
                    
                        <button [disabled]="!isFormValid || totalPrice<=0" class="pay-button d-block w-100" (click)="createOrder(paymentTypes.cash.id)">Pay with Cash</button>
                    </div>

                    <div class="col-sm-6 pt-3">
                    
                        <button [disabled]="!isFormValid || totalPrice<=0" class="pay-button d-block w-100" (click)="createOrder(paymentTypes.card.id)">Pay with Card</button>
                    </div>

                   
                </div>
              </div>
              <div class="col-sm-6">
                  <div class="d-fle flex-column h-100 rounded-3 bg-secondary px-3 px-sm-4 py-4">
                      <h2 class="h5 pb-3">{{"menu.YOUR-TOTAL" | translate}}</h2>
                      <div class="d-flex justify-content-between fs-md border-bottom pb-3 mb-3">
                          <span>{{"checkout.SUBTOTAL" | translate}}:</span><span class="text-heading">{{totalPrice | number: '1.2-2'}} <small>{{'currency.CURRENCY_VALUE' | translate}}</small></span>
                      </div>
                      <div class="d-flex justify-content-between fs-md border-bottom pb-3 mb-3" *ngIf="discount">
                          <span>{{"checkout.DISCOUNT" | translate}}:</span><span class="text-heading">{{discount | number: '1.2-2'}} <small>{{'currency.CURRENCY_VALUE' | translate}}</small></span>
                      </div>
                      <div class="d-flex justify-content-between fs-md border-bottom pb-3 mb-3">
                        <span>{{ 'CART.SHIPPING' | translate }}:</span><span class="text-heading">{{shippingCost | number: '1.2-2'}} <small>{{'currency.CURRENCY_VALUE' | translate}}</small></span>
                    </div>
                      <!-- <div class="d-flex justify-content-between fs-md border-bottom pb-3 mb-3">
                          <span>{{"checkout.SHIPPING-FEE" | translate}}:</span><span class="text-heading">$6.<small>00</small></span>
                      </div> -->
                      <div class="d-flex justify-content-between fs-md mb-2"><span>{{"menu.TOTAL" | translate}}:</span><span
                              class="text-heading fw-medium">{{totalPriceWithShipping | number: '1.2-2'}} <small>{{'currency.CURRENCY_VALUE' | translate}}</small></span></div>
                  </div>
              </div>
          </div>
      </form>
<!--       <div class="col-sm-4 pt-3">
        <button class="btn btn-primary d-block w-100 mb-2" (click)="createSale(paymentTypes.cash.id)">Pay with Cash</button>
        <button class="btn btn-primary d-block w-100" (click)="createSale(paymentTypes.card.id)">Pay with Card</button>

    </div> -->
  </div>
</div>


<form action='https://www.cpay.com.mk/client/Page/default.aspx?xml_id=/mk-MK/.loginToPay/.simple/' method='post'>
    <input id='AmountToPay' class="payment-input" name='AmountToPay' type='hidden' />
    <input id='PayToMerchant' class="payment-input" name='PayToMerchant' value='1000002164' type='hidden' />
    <input id='MerchantName' class="payment-input" name='MerchantName' value='BD ORGANIK DERM' type='hidden' />
    <input id='AmountCurrency' class="payment-input" name='AmountCurrency' value='MKD' type='hidden' />
    <input id='Details1' class="payment-input" name='Details1' value='' type='hidden' />
    <input id='Details2' class="payment-input" name='Details2' type='hidden' />
    <input id='PaymentOKURL' class="payment-input" size='10' name='PaymentOKURL' value='{{successUrl}}' type='hidden' />
    <input id='PaymentFailURL' class="payment-input" size='10' name='PaymentFailURL' value='{{failUrl}}' type='hidden' />
    <input id='CheckSumHeader' class="payment-input" name='CheckSumHeader' value='08AmountToPay,PayToMerchant,MerchantName,AmountCurrency,Details1,Details2,PaymentOKURL,PaymentFailURL' type='hidden' />
    <input id='CheckSum' name='CheckSum' type='hidden' />
</form>


<form method="post" [action]="PortalUrl" #paymentFormView class="hidden-form">
    <input type="hidden" name="Amount" [value]="amount" />
    <input type="hidden" name="BillToCompany" value="BillToCompany" />
    <input type="hidden" name="CallbackUrl" [value]="CallbackUrl" />
    <input type="hidden" name="Clientid" [value]="Clientid" />
    <input type="hidden" name="OkUrl" [value]="OkUrl" />
    <input type="hidden" name="FailUrl" [value]="FailUrl" />
    <input type="hidden" name="Trantype" [value]="Trantype" />
    <input type="hidden" name="Instalment" [value]="Instalment" />
    <input type="hidden" name="Currency" [value]="Currency" />
    <input type="hidden" name="Rnd" [value]="Rnd" />
    <input type="hidden" name="Storetype" [value]="Storetype" />
    <input type="hidden" name="Lang" [value]="Lang" />
    <input type="hidden" name="hashAlgorithm" value="ver3" />
    <input type="hidden" name="BillToName" value="name" />
    <input type="hidden" name="Refreshtime" [value]="Refreshtime" />
    <input type="hidden" name="Hash" [value]="Hash" />
</form>
