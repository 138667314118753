import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { LoginResponse, Product } from 'src/app/modules/interfaces';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgetPasswordModalComponent } from 'src/app/components/forget-password-modal/forget-password-modal.component';
import Swal from 'sweetalert2';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-login',
    standalone: false,
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {

    signInForm = new FormGroup({

        Username : new FormControl(''),
        password : new FormControl(''),
    });

    labelError!: string;
    labelInfo!: string;
    resetPassword = false;
    sendEmailResult = "";

  @Output() isUserLoggedInChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() userData: EventEmitter<any> = new EventEmitter<any>();



  constructor(
    private cookieService: CookieService,
    private cartService: CartService,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserProfileService,
    private sharedService: DataSharedService,
    private conomeService: ConomeECommerceService,
    private translate: TranslateService
  ){
    
  }
  productsFromConome:any[] = []
  userId:string = ''
  title='';
  invalidCredentials='';
  confirmationFailed='';
  confirmationFailedText='';
  confirmationSuccessful='';    
  confirmationSuccessfulText=''

  ngOnInit(){
    this.loadTranslations();

    this.translate.onLangChange.subscribe(() => {
        this.loadTranslations();
    });

    const currentUrl = this.router.url;
    
    this.translate.get([
        this.confirmationFailed,
        this.confirmationFailedText,
        this.confirmationSuccessful,
        this.confirmationSuccessfulText
    ]).subscribe(translations => {
        if (currentUrl.includes('login/verification-failed')) {
            this.showSwalAlert(
                this.confirmationFailed,
                this.confirmationFailedText,
                'error'
            );
        } else if (currentUrl.includes('login/verification-successful')) {
            this.showSwalAlert(
                this.confirmationSuccessful,
                this.confirmationSuccessfulText,
                'success'
            );
        }
    });
  }

  showSwalAlert(title: string, text: string, icon: 'success' | 'error'): void {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      confirmButtonText: 'OK'
    }).finally(() => {
        this.router.navigate(['/login']);
    });
  }

  loadTranslations() {
    const translationKeys = [
        'login-page.ERROR-MESSAGE',
        'login-page.FAILED',
        'login-page.CONFIRMATION-FAILED',
        'login-page.CONFIRMATION-FAILED-TEXT',
        'login-page.CONFIRMATION-SUCCESSFUL',
        'login-page.CONFIRMATION-SUCCESSFUL-TEXT',
    ];

    this.translate.get(translationKeys).subscribe(translations => {
        this.invalidCredentials = translations['login-page.ERROR-MESSAGE'];
        this.title = translations['login-page.FAILED'];
        this.confirmationFailed = translations['login-page.CONFIRMATION-FAILED'];
        this.confirmationFailedText=translations['login-page.CONFIRMATION-FAILED-TEXT'];
        this.confirmationSuccessful = translations['login-page.CONFIRMATION-SUCCESSFUL'];
        this.confirmationSuccessfulText=translations['login-page.CONFIRMATION-SUCCESSFUL-TEXT'];
    });
}
  
  getProducts() {
    this.cartService.fetchCartData(this.userId).subscribe({
        next: (products: any[]) => {
            this.productsFromConome = products;
            this.cartService.updateCartProducts(products);
        }
    });
}
  login(data:any) {
      this.labelError = 'error';
      this.labelInfo = 'info';
      this.userService
          .login(data)
          .subscribe(
              (response) => {
                    const httpResponse: LoginResponse = response as LoginResponse;
                    this.sharedService.setLoginStatus(true);
                    this.userData.emit(httpResponse.user);
                    this.cookieService.set(LOCAL_STORAGE.TOKEN, httpResponse.token);
                    this.cookieService.set("username", httpResponse.user.displayName);
                    this.cookieService.set('userID', httpResponse.id.toString());                    
                    this.userService.setUserId(httpResponse.id.toString());
                    this.userId = httpResponse.id.toString()
                    this.getProducts();
                  this.router.navigate(['home']);
              },
              (error) => {
                  // Login failed, handle the error (e.g., display an error message)
                  Swal.fire({
                      icon: 'error',
                      title: this.title,
                      text: this.invalidCredentials,
                      confirmButtonText: 'OK',
                  });
                  this.labelError = 'login-page.ERROR-MESSAGE';
              }
          );
  }

  openForgotPasswordModal() {
      const modalRef = this.modalService.open(ForgetPasswordModalComponent);
      modalRef.result.then(
          (result) => {
          },
          (reason) => {
          }
      );
  }
}
