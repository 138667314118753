<div class="shadow-lg myprofile-card">
    <h5 class="title">Edit your profile</h5>
    <div class="row gx-4 gy-3">
        <div class="col-sm-6">
            <label class="form-label" for="dashboard-fn">
                {{"user-profile.FIRST-NAME" | translate}}
            </label>
            <input class="form-control" type="text" id="dashboard-fn" value="{{user?.firstname}}" disabled>
        </div>
        <div class="col-sm-6">
            <label class="form-label" for="dashboard-ln">
                {{"user-profile.LAST-NAME" | translate}}
            </label>
            <input class="form-control" type="text" id="dashboard-ln" value="{{user?.lastname}}" disabled>
        </div>
        <div class="col-sm-6">
            <label class="form-label" for="email">
                {{"user-profile.EMAIL" | translate}}
            </label>
            <input class="form-control" type="text" id="email" value="{{user?.email}}">
        </div>
        <div class="col-sm-6">
            <label class="form-label" for="dashboard-address">
                {{"user-profile.ADDRESS" | translate}}
            </label>
            <input class="form-control" type="text" id="dashboard-address" value="{{user?.zip_code}}">
        </div>
    </div>

    <div class="change-password-section margin-1-top">
        <form [formGroup]="changePasswordForm">
            <div class="form-group">
                <label class="form-label" for="dashboard-address">
                    Password Changes
                </label>
                <div class="input-group mb-4 ">
                    <input class="form-control input-lg"
                        placeholder="{{'user-profile.NEW-PASSWORD'| translate}}"
                        formControlName="NewPassword"
                        [type]="showNewPassword? 'text': 'password'" />
                    <span class="input-group-text"><i
                            class="fa"
                            [ngClass]="{'fa-eye-slash':!showNewPassword,'fa-eye':showNewPassword}"
                            (click)="toggleNewPassword()"></i></span>
                </div>
                <div class="input-group mb-4">
                    <input class="form-control input-lg"
                        placeholder="{{'user-profile.RETRY-NEW-PASSWORD'| translate}}"
                        formControlName="ConfirmNewPassword"
                        [type]="showConfirmationPassword? 'text' : 'password'" />
                    <span class="input-group-text"><i
                            class="fa"
                            [ngClass]="{'fa-eye-slash':!showConfirmationPassword,'fa-eye':showConfirmationPassword}"
                            (click)="toggleConfirmationPassword()"></i></span>
                </div>
                <div *ngIf="isFormSubmitted && labelSucces" class="alert alert-success">
                    {{ labelSucces }}
                  </div>
                  <div *ngIf="isFormSubmitted && labelError" class="alert alert-danger">
                    {{ labelError }}
                  </div>
            </div>
            </form>
            <div class="row">
                <div class="col-12 btn-container">
                     <div class="row">
                        <!-- <button 
                            class="cancel-change-password-button">
                            {{"user-profile.CANCEL" | translate}}
                        </button> -->

                        <button (click)="changepassword(changePasswordForm.value)"
                            class="change-password-button">
                            {{'user-profile.RESET-MY-PASSWORD'|
                            translate}}
                        </button>
                    </div>
                </div>
            </div>
    </div>
</div>
