/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Product } from 'src/app/modules/interfaces';
import {environment} from "../../../environments/environment";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, private translateService:TranslateService) {}

  products: Product[] = [];
  
  getProducts() {
    return this.http.get<{data:any[],meta:object}>(`${environment.backendUrl}/api/products?sort=name_${this.currentLanguage}&populate=*`)
  }

  getRandomProducts() {
    return this.http.get<{ data: any[] }>(`${environment.backendUrl}/api/product/random?populate=*`);
  }
  

  getProductById(id:number) {
    return this.http.get<{data:any,meta:object}>(`${environment.backendUrl}/api/products/${id}?populate=*`);
  }
  

  updateProductById(product:Product,newQuantity:number) {
    return this.http.put(`${environment.backendUrl}/api/products/${product.id}`,{data:{quantity_available: newQuantity}});    
  }

  
  get currentLanguage(){
    return this.translateService.currentLang
  }

  searchProducts(companyId: number, searchText: string, page: number = 1, pageSize: number = 20, orderByDate: boolean = false, categoryIds: number[] | null = null) {
    let params = new HttpParams()
      .set('companyId', companyId.toString())
      .set('searchText', searchText)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('orderByDate', orderByDate.toString());
    
    const hasCategories = categoryIds && categoryIds.length > 0;

    if (hasCategories) {
      const body = { Ids: categoryIds };
      return this.http.post<{ data: any[], meta: object }>(
        `${environment.conomeUrl}/api/EcommerceApi/SearchProducts`, 
        body, 
        { params }
      );
    } else {
      return this.http.post<{ data: any[], meta: object }>(
        `${environment.conomeUrl}/api/EcommerceApi/SearchProducts`,
        {},
        { params }
      );
    }
  }
}
