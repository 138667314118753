import { AfterViewInit, OnInit, Component, PLATFORM_ID, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../../modules/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
    selectedLanguage = 'mk';
    flagLanguage = '';
    languages = ['sq', 'mk'];

    constructor(private cookieService: CookieService, private translateService: TranslateService, private modalService: NgbModal, @Inject(PLATFORM_ID) private platformId: Object) {
        /* if(isPlatformBrowser(this.platformId)){
            const selectedLanguage = localStorage.getItem(LOCAL_STORAGE.SELECTED_LANGUAGE);

            if(selectedLanguage) {
                this.translateService.setDefaultLang(selectedLanguage);
                this.translateService.use(selectedLanguage);
            }
            else {
                this.translateService.setDefaultLang('mk');
                this.translateService.use('mk');
            }  
        } */
          
    }

    ngOnInit(): void {
        const selectedLanguage = this.cookieService.get(LOCAL_STORAGE.SELECTED_LANGUAGE);
        if(this.languages.includes(selectedLanguage)) {
            this.translateService.setDefaultLang(selectedLanguage);
            this.translateService.use(selectedLanguage);
        }
    }

    get currentLanguage() {
        return this.translateService.currentLang
    }

    changeLanguage(language:string){
        this.translateService.use(language);
        /* localStorage.setItem(LOCAL_STORAGE.SELECTED_LANGUAGE,language); */
        this.cookieService.set(LOCAL_STORAGE.SELECTED_LANGUAGE, language);
        this.selectedLanguage = language;
        language == 'sq' ? this.flagLanguage = 'al' : this.flagLanguage = language;

    } 
}
