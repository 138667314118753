import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(private http: HttpClient) {}

    login(username: string, password: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        const user = this.http.post(
            `${environment.backendUrl}/api/auth/local`,
            {
                identifier: username,
                password: password,
            },
            httpOptions
        );
        return user;
    }
}
