import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EmailServiceService {

    constructor(private http:HttpClient) { }

    sendEmail(data?:any):Observable<any>{
        return this.http.post(`${environment.backendUrl}/api/send-email`, data);
    }
}
