import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { timer } from 'rxjs';
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { Product } from 'src/app/modules/interfaces';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import { EmailServiceService } from 'src/app/services/EmailService/email-service.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';

@Component({
    selector: 'app-signup',
    standalone: false,
    templateUrl: './signup.component.html',
    styleUrl: './signup.component.scss'
})
export class SignupComponent {
    signupForm: FormGroup;

    isFormValid = false;
    registrationSucces = false;
    errorMessage: boolean = false;
    successMessage: boolean = false;

    emailSubject='';
    welcomeToLozz='';
    lozzText='';    
    confirmEmail=''

    constructor(
    private formBuilder: FormBuilder,
    private cartService:CartService,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private userService: UserProfileService,
    private emailService:EmailServiceService,
    private translate: TranslateService,

    ){

        this.signupForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            lastname: new FormControl(''),
            email: ['', [Validators.required, Validators.email]],
            PlaceId : new FormControl(''),
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    this.passwordMatchValidator,
                ],
            ],
            confirmPassword: new FormControl(''),
            Address : new FormControl(''),
            AddressNumber : new FormControl(''),
            BankName : new FormControl(''),
            IBAN : new FormControl(''),
            SWIFT : new FormControl(''),
            BankAccount : new FormControl(''),
            phoneNumber: new FormControl('')
        });

        this.signupForm.valueChanges.subscribe((value) => {
            if (this.signupForm.valid) {
                this.isFormValid = true;
            } else {
                this.isFormValid = false;
            }
        });
    }


    onSubmit() {
        if(isPlatformBrowser(this.platformId)){

            const companyID = parseInt(this.cookieService.get("companyID"))
            localStorage.clear();
            const productsArray: Product[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PRODUCTS) || '[]');
            this.cartService.updateCartProducts(productsArray);
            if (this.signupForm.valid) {
                const signUpData = {
                    Name: this.signupForm.value.name,
                    /* Surname: this.signupForm.value.lastname, */
                    Email: this.signupForm.value.email,
                    Password: this.signupForm.value.password,
                    isEcommerce: true,
                    CompanyID: companyID
                    /* confirmPassword: this.signupForm.value.confirmPassword,
            Address: this.signupForm.value.Address,
            AddressNumber: this.signupForm.value.AddressNumber,
            PhoneNumber: this.signupForm.value.phoneNumber */
                };
                const ecommerce = true;
                this.userService.register(signUpData,ecommerce).subscribe(
                    (response) => {
                        this.sendEmail(response.url)
                        this.signupForm.reset();
                        this.registrationSucces = true;
                        this.successMessage=true;
                        timer(3000).subscribe(() => {
                            this.registrationSucces = false;
                        });
                    },
                    (error) => {
                        if (error.status === 401) {
                            this.errorMessage = true;
                        }
                    }
                );
            }
        }
    }

    translatedTextForemail() {
        this.emailSubject = " " + this.translate.instant("sign-up.EMAIL-SUBJECT");
        this.welcomeToLozz = this.translate.instant("sign-up.WELCOME-TO-LOZZ");
        this.lozzText = this.translate.instant("sign-up.EMAIL-TEXT");
        this.confirmEmail = this.translate.instant("sign-up.CONFIRM-EMAIL");
      }

    sendEmail(confirmUrl:string) {
        this.translatedTextForemail();
        const subject = this.emailSubject;
        const email = this.signupForm.value.email;
        const signupHTML = `
        <!DOCTYPE html>
        <html>
        <head>
            <style>
                * {
                    box-sizing: border-box;
                }
            </style>
        </head>
        <body>
            <table align="center" width="100%" cellpadding="0" cellspacing="0" style="background-color: #f5f5f5; padding: 20px;">
                <tr>
                    <td align="center">
                        <!-- Main Container -->
                        <table width="350" cellpadding="0" cellspacing="0" style="background-color: #ffffff; box-shadow: 0px 3px 6px #00000029; padding: 20px; text-align: center; font-family: Arial, sans-serif;">
                            
                            <!-- First Image -->
                            <tr>
                                <td style="padding-bottom: 20px;">
                                    <img src="https://lozzcms.isolvedev.website/uploads/thumbnail_lozz_05983e88f6.png?updatedAt=2024-11-07T10%3A44%3A45.389Z" alt="LozzToys Email" style="width: 180px; height: 150px;">
                                </td>
                            </tr>

                            <!-- Title Text -->
                            <tr>
                                <td style="font-size: 26px; color: #262626; font-weight: 600; padding-bottom: 15px;">
                                    ${this.welcomeToLozz}
                                </td>
                            </tr>

                            <!-- Body Text -->
                            <tr>
                                <td style="color: #646464; padding-bottom: 20px;">
                                    ${this.lozzText}
                                </td>
                            </tr>

                            <!-- Confirmation Button -->
                            <tr>
                                <td style="padding-bottom: 15px;">
                                    <a href="${confirmUrl}" style="background-color: #f6821f; color: white; text-decoration: none; font-size: 16px; padding: 15px 0; display: inline-block; width: 100%; border-radius: 4px;">
                                        ${this.confirmEmail}
                                    </a>
                                </td>
                            </tr>

                            <!-- Second Image -->
                            <tr>
                                <td>
                                    <img src="https://lozzcms.isolvedev.website/uploads/thumbnail_lozztoys_logo_2f35738e5d.png?updatedAt=2024-11-07T10%3A44%3A18.448Z" alt="LozzToys Logo" style="width: 130px; height: 100px;">
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </body>
        </html>
        `;
        const emailData = {
            to: email,
            subject: subject,
            html: signupHTML
        };
        this.emailService.sendEmail(emailData).subscribe();
    }

    get signupform() {
        return this.signupForm.controls;
    }




    passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
        const password = control.get('password')?.value;
        const confirmPassword = control.get('confirmPassword')?.value;
        const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]+$/;

        if (password !== confirmPassword) return { passwordMatch: true };
        if (!password) return null;

        if (password !== confirmPassword) return { passwordMatch: true };

        if (!password.match(passwordRegex)) return { invalidPassword: true };

        if (password !== confirmPassword) return { passwordMatch: true };

        return null;
    }
}
