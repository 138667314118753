<div class="container">
  <div class="form-container">
    <div class="p-2">
      <h2>{{'user-profile.RESET-PASSWORD' | translate}}</h2> 
      <label for="email">{{'user-profile.NEW-PASSWORD'|translate}}</label> 
      <input type="password" id="email" [(ngModel)]="newPassword" class="form-control form-control-sm"/>
      <br/>
      <label for="email">{{'user-profile.RETYPE-NEW-PASSWORD'|translate}}</label> 
      <input type="password" id="email" [(ngModel)]="repeatNewPassword" class="form-control form-control-sm"/>

      <br>
      <button class="btn btn-primary" (click)="resetPassword()">{{'user-profile.RESET'|translate}}</button> 
      <p *ngIf="resetResult.length>0">{{resetResult}}</p>
    </div>
  </div>
</div>