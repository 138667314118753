import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    private renderer: Renderer2;

    constructor(
    private http: HttpClient,
    private meta: Meta,
    private titleService: Title,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    getSeo() {
        return this.http.get(`${environment.backendUrl}/api/seo?populate=*`);
    }

    public updateGoogleVerification(tag: any) {
        this.meta.updateTag({ name: 'google-site-verification', content: tag?.verificationToken ? tag?.verificationToken : 'verification_token' });
    }

    public updateMetaAndSocialTags(tag: any) {
        this.titleService.setTitle(tag?.metaTitle || '');

        this.updateOrCreateMetaTag('description', tag?.metaDescription || '');
        this.updateOrCreateMetaTag('keywords', tag?.keywords || '');
        this.updateOrCreateMetaTag('robots', tag?.metaRobots || '');
        this.updateOrCreateMetaTag('viewport', tag?.metaViewport || '');
        this.updateOrCreateMetaTag('canonical', tag?.canonicalURL || '');

        tag.metaSocial.forEach((social: any) => {
            this.updateOrCreateMetaTag(`og:${social.socialNetwork.toLowerCase()}:title`, social.title);
            this.updateOrCreateMetaTag(`og:${social.socialNetwork.toLowerCase()}:description`, social.description);
            if (social.image?.data) {
                this.updateOrCreateMetaTag(`og:${social.socialNetwork.toLowerCase()}:image`, social.image.data.url);
            }
        });

        if (tag.structuredData) {
            this.addStructuredData(tag.structuredData);
        }
    }

    private updateOrCreateMetaTag(name: string, content: string) {
        const tag = this.meta.getTag(`name='${name}'`);
        if (tag) {
            this.meta.updateTag({ name, content });
        } else {
            this.meta.addTag({ name, content });
        }
    }

    private addStructuredData(data: any) {
        const scriptTag = this.document.createElement('script');
        scriptTag.type = 'application/ld+json';
        scriptTag.text = JSON.stringify(data);
        const existingScript = this.document.querySelector('script[type="application/ld+json"]');
        if (existingScript) {
            this.document.head.removeChild(existingScript);
        }
        this.document.head.appendChild(scriptTag);
    }

    private loadGoogleAnalytics(googleAnalyticsId: string): void {
        const script = this.renderer.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
        script.async = true;
        this.renderer.appendChild(this.document.head, script);

        const inlineScript = this.renderer.createElement('script');
        const scriptContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleAnalyticsId}',{
        'anonymize_ip':true,
        'cookie_flags': 'SameSite=None;Secure'
      });
    `;
        inlineScript.text = scriptContent;
        this.renderer.appendChild(this.document.head, inlineScript);
    }

    getAndUpdateSeo() {
        this.getSeo().subscribe((response: any) => {
            const seoData = response.data.attributes.seo[0];
            const seoGoogle = response.data.attributes.seoGoogle;
            const metaSocial = response.data.attributes.metaSocial;

            this.updateMetaAndSocialTags({ ...seoData, metaSocial });
            this.updateGoogleVerification(seoGoogle);
            this.loadGoogleAnalytics(seoGoogle.googleAnalyticsId);
        });
    }
}

