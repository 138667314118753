import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-forget-password-modal',
    standalone: false,
    templateUrl: './forget-password-modal.component.html',
    styleUrl: './forget-password-modal.component.scss'
})
export class ForgetPasswordModalComponent {


    forgotPasswordForm = new FormGroup({

        Username : new FormControl(''),
    
    });

    constructor(private userService: UserProfileService, private modalService: NgbModal,private translate: TranslateService){

    }

    successTitle='';
    successDesc='';
    failedTitle='';
    failedDesc='';

    ngOnInit(){
        this.loadTranslations();
    
        this.translate.onLangChange.subscribe(() => {
            this.loadTranslations();
        });
      }
    
      loadTranslations() {
        const translationKeys = [
           'login-page.FORGOT-PASSWORD-SUCCESS',
           'login-page.FORGOT-PASSWORD-SUCCESS-DESC',
           'login-page.FORGOT-PASSWORD-FAIL',
           'login-page.FORGOT-PASSWORD-FAIL-DESC',
        ];
    
        this.translate.get(translationKeys).subscribe(translations => {
            this.successTitle = translations['login-page.FORGOT-PASSWORD-SUCCESS'];
            this.successDesc = translations['login-page.FORGOT-PASSWORD-SUCCESS-DESC'];
            this.failedTitle = translations['login-page.FORGOT-PASSWORD-FAIL'];
            this.failedDesc = translations['login-page.FORGOT-PASSWORD-FAIL-DESC'];
        });
    }
  
    forgotPassword(username: any) {
        const data = { ...username };
  
        this.userService.forgotpassword(data).subscribe({
            next: (res) => {
                Swal.fire({
                    title: this.successTitle,
                    text: this.successDesc,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            },
            error: (error) => {
                Swal.fire({
                    title: this.failedTitle,
                    text: this.failedDesc,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            }
        });
    }

    closemodal() {
        this.modalService.dismissAll();
    }
}
