import { Injectable } from '@angular/core';
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class AuthServiceService {

    constructor(private http:HttpClient, private cookieService: CookieService) { }

    getLoggedInUserId(): Promise<number | null> {
        const token = this.cookieService.get(LOCAL_STORAGE.TOKEN);
        if (token) {
            const payload: any = JSON.parse(atob(token.split('.')[1]));
            return Promise.resolve(payload.id);
        }
        return Promise.resolve(null);
    }
}
