import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {

  @Input() totalItems = 0;
  @Input() indexPage = 0;
  @Input() itemsPerPage = 10;
  @Output() indexPageChange = new EventEmitter<number>();

  roundedUpPage = 0;

  ngOnChanges() {
      this.roundedUpPage = this.totalItems ===0 ? 1 :Math.ceil(this.totalItems / this.itemsPerPage);
  }

  prevPage() {
      this.indexPage--;
      this.indexPageChange.emit(this.indexPage)
  }

  nextPage() {
      this.indexPage++;
      this.indexPageChange.emit(this.indexPage)
  }


}
