<div class="bg pt-4 pb-3">
    <div class="container d-lg-flex py-2">
      <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
            <ng-container *ngFor="let item of breadcrumbItems">
                <li class="breadcrumb-item text-nowrap" [ngClass]="{'accent': item.active === true}"><a class="" routerLink="{{item.link}}"><i class="ci-home" *ngIf="item.label === homeLabel"></i>{{ item.label }}</a></li>
            </ng-container>
          </ol>
        </nav>
      </div>
    </div>
</div>
