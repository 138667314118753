import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../../services/user/user-profile.service';
import { LOCAL_STORAGE } from '../../modules/constants';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser } from '@angular/common';
import { getUser } from 'src/app/modules/Users';


@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
    breadCrumbItems!: any;

    constructor(private translateService: TranslateService){}
    
    ngOnInit(): void {
        this.translateBreadcrumbItems();
        this.translateService.onLangChange.subscribe(() => {
            this.translateBreadcrumbItems();
        })
    }
  
    private async translateBreadcrumbItems() {
        const homeLabel = await this.translateService.get("menu.HOME").toPromise();
        const firstLabel = await this.translateService.get("menu.MY-ACCOUNT").toPromise();

        this.breadCrumbItems = [
            { label: homeLabel, link: '/home' },
            { label: firstLabel, link: '/userprofile' },
        ];
    }
    
} 
