import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    code = '';
    newPassword = '';
    repeatNewPassword = '';
    resetResult = "";

    constructor(private route: ActivatedRoute, private http: HttpClient, private translate:TranslateService) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.code = params.code;
        });
    }

    resetPassword() {
        this.http.post(
            `${environment.backendUrl}/api/auth/reset-password`,
            {
                code: this.code, // code contained in the reset link of step 3.
                password: this.newPassword,
                passwordConfirmation: this.repeatNewPassword,
            }
        ).subscribe(reset=>{
            this.resetResult=this.translate.instant("user-profile.RESET-PASSWORD-SUCCESS")
        },
        error=>{
            this.resetResult=error.error.error.message

        })
    }
}
