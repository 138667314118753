import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-aboutus',
    standalone: false,
    templateUrl: './aboutus.component.html',
    styleUrl: './aboutus.component.scss'
})
export class AboutusComponent implements OnInit {

    currentLanguage = this.translateService.currentLang;
    breadCrumbItems!: any;
    aboutUs: any;
    cmsUrl = environment.backendUrl;

    constructor(private cmsService: LozztoysCmsService, private translateService: TranslateService){

    }

    ngOnInit(): void {
        this.getAboutUsSection();
        this.translateBreadcrumbItems();
        this.translateService.onLangChange.subscribe((res) => {
            if (this.currentLanguage !== res.lang) {
                this.currentLanguage = res.lang;
                this.getAboutUsSection();
                this.translateBreadcrumbItems();
            }
        });
    }

    getAboutUsSection(){
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getAboutUs(populateAndLocale).subscribe({
            next: (res:any) => {
                this.aboutUs = res.data.attributes;
            },
            error: (err) => {
                console.error(err);
            }
        })
    }


    private async translateBreadcrumbItems() {
        const homeLabel = await this.translateService.get("menu.HOME").toPromise();
        const firstLabel = await this.translateService.get("menu.ABOUT-US").toPromise();

        this.breadCrumbItems = [
            { label: homeLabel, link: '/home' },
            { label: firstLabel, link: '/about-us' },
        ];
    }
}
