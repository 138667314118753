import { Component } from '@angular/core';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';

@Component({
    selector: 'app-my-returns',
    standalone: false,
    templateUrl: './my-returns.component.html',
    styleUrl: './my-returns.component.scss'
})
export class MyReturnsComponent {
    orders: any[] = [];
    paginatedOrders: any[] = [];
    clientConomeId:string='';
    totalItems = 0;
    indexPage = 0;
    itemsPerPage = 5;

    constructor (private conomeECommerceService:ConomeECommerceService,private userProfileService:UserProfileService) {}

    ngOnInit(): void {
        this.clientConomeId=this.userProfileService.getUserId();
        this.loadOrders(this.clientConomeId);
    }

    loadOrders(clientConomeId: string): void {
        this.conomeECommerceService.getOrders(Number(clientConomeId)).subscribe(
          (data) => {
            this.orders = data;
            this.totalItems = this.orders.length;
            this.updatePaginatedOrders();
          },
          (error) => {
            console.error('Error fetching orders:', error);
          }
        );
      }
    
    updatePaginatedOrders(): void {
        const start = this.indexPage * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        this.paginatedOrders = this.orders.slice(start, end);
    }

    onPageChange(newPage: number): void {
        this.indexPage = newPage;
        this.updatePaginatedOrders();
    }

    getStatusClass(status: string): string {
        switch (status.toLowerCase()) {
            case 'shipped':
                return 'status-shipped';
            case 'processing':
                return 'status-processing';
            case 'canceled':
                return 'status-cancelled';
            case 'refunded':
                return 'status-refunded';
            default:
                return 'status-default';
        }
    }
}
