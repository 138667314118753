<div class="d-flex flex-row pointer">
    <p class="nav-link dropdown-toggle text-secondary" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" 
        aria-haspopup="true" aria-expanded="false">
        <span class="fi fi-{{ currentLanguage === 'sq' ? 'al' : 'mk'}} flag-img"></span>
        {{currentLanguage | uppercase}} / {{"currency.CURRENCY_VALUE" | translate}}
</p>
    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
        <p class="dropdown-item" (click)="changeLanguage('sq')">
            <span class="fi fi-al flag-img"></span>{{"menu.ALBANIAN" | translate}}
        </p>
        <p class="dropdown-item" (click)="changeLanguage('mk')">
            <span class="fi fi-mk flag-img"></span>{{"menu.MACEDONIAN" | translate}}</p>
    </div>
</div>
