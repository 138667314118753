<div class="container">
    <app-banner  [breadcrumbItems]="breadCrumbItems"></app-banner>
<div class="user-profile-container">
    <div class="sidebar">
      <ul>
        <li routerLinkActive="active"><a routerLink="my-profile">{{"user-profile.MY-PROFILE" | translate}}</a></li>
        <li routerLinkActive="active"><a routerLink="my-orders">{{"user-profile.MY-ORDERS" | translate}}</a></li>
        <li routerLinkActive="active"><a routerLink="wishlist">{{"user-profile.WISHLIST" | translate}}</a></li>
      </ul>
    </div>
  
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>