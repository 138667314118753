import { Component } from '@angular/core';
import { LoaderInterceptor } from 'src/app/services/interceptor/loader.interceptor';
import { LoaderService } from 'src/app/services/LoaderService/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    public loading$ = this.loaderService.loading$;

    constructor(public loaderService: LoaderService){

    }

}