import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../environments/environment"
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpRequestsInterceptor implements HttpInterceptor {

    constructor(@Inject(PLATFORM_ID) private platformId: Object, private cookieService: CookieService){
        
    }
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        // Check if the request already has an Authorization header
        if (!request.headers.has('Authorization')) {
            const API_Token =  environment?.company_token;
            if (API_Token) {
                // If we have a token, we set it to the header
                request = request.clone({
                    setHeaders: { authorization:  API_Token }
                });
            }
        }
      
      
        return next.handle(request);
    }
}
