import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../LoaderService/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private readonly excludedUrls = [
        '/api/EcommerceApi/ShippingPrice'
    ];

    constructor(private loaderService: LoaderService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isExcluded = this.excludedUrls.some(url => request.url.includes(url));

        if (!isExcluded) {
            this.loaderService.show();
        }

        return next.handle(request).pipe(
            finalize(() => {
                if (!isExcluded) {
                    this.loaderService.hide();
                }
            })
        );
    }
}
