import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConomeECommerceService {

    apiUrl = environment.conomeUrl
    productsSource = new BehaviorSubject<any[]>([]);
    products$ = this.productsSource.asObservable();
    private categoriesSource = new BehaviorSubject<any[]>([]);
    categories$ = this.categoriesSource.asObservable();
    productsFromCartSource = new BehaviorSubject<any[]>([]);
    productsFromCart$ = this.productsFromCartSource.asObservable();
    private productsFromWishlistSource = new BehaviorSubject<any[]>([]);
    productsFromWishlist$ = this.productsFromWishlistSource.asObservable();
    private topPicksSource = new BehaviorSubject<any[]>([]);
    topPicksProducts$ = this.topPicksSource.asObservable();
    private flashSalesSource = new BehaviorSubject<any[]>([]);
    flashSalesProducts$ = this.flashSalesSource.asObservable();
    private newArrivalsSource = new BehaviorSubject<any[]>([]);
    newArrivalsProducts$ = this.newArrivalsSource.asObservable();

    constructor(private http: HttpClient) { }

    setNewArrivals(products: any) {
        products = Array.isArray(products)
        ? products.reduce((acc: any[], val: any[]) => acc.concat(val), [])
        : products;
        this.newArrivalsSource.next(products);
    }

    setFlashSales(products: any){
        products = Array.isArray(products)
        ? products.reduce((acc: any[], val: any[]) => acc.concat(val), [])
        : products;
        this.flashSalesSource.next(products);
    }

    setTopPicks(products: any){
        products = Array.isArray(products)
        ? products.reduce((acc: any[], val: any[]) => acc.concat(val), [])
        : products;
        this.topPicksSource.next(products);
    }

    setproductsFromCart(products: any) {
        const flattenedProducts = Array.isArray(products)
            ? products.reduce((acc: any[], val: any[]) => acc.concat(val), [])
            : products;
        const currentProducts = this.productsFromCartSource.getValue();
        if (JSON.stringify(currentProducts) === JSON.stringify(flattenedProducts)) {
            return;
        }
        const uniqueProducts = this.removeDuplicatesById(flattenedProducts);
        this.productsFromCartSource.next(uniqueProducts);
    }

    private removeDuplicatesById(products: any[]): any[] {
        const seenIds = new Set();
        return products.filter(product => {
            if (seenIds.has(product.id)) {
                return false;
            }
            seenIds.add(product.id);
            return true;
        });
    }
    setproductsFromWishlist(products: any) {
        const flattenedProducts = Array.isArray(products)
            ? products.reduce((acc: any[], val: any) => acc.concat(val), [])
            : products;
    
        const currentProducts = this.productsFromWishlistSource.getValue();

        if (this.areProductsEqual(currentProducts, flattenedProducts)) {
            return;
        }
        this.productsFromWishlistSource.next(flattenedProducts);
    }
    
    areProductsEqual(arr1: any[], arr2: any[]): boolean {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((product, index) => JSON.stringify(product) === JSON.stringify(arr2[index]));
    }
    
    setProductsData(products: any){
        products = Array.isArray(products)
        ? products.reduce((acc: any[], val: any[]) => acc.concat(val), [])
        : products;
        this.productsSource.next(products);
    }

    getProductsData(){
        return this.productsSource.getValue();
    }

    setCategoriesData(categories: any){
        categories = Array.isArray(categories)
        ? categories.reduce((acc: any[], val: any[]) => acc.concat(val), [])
        : categories;
        this.categoriesSource.next(categories);        
    }

    getCategoriesData(){
        return this.categoriesSource.getValue();
    }

    getCompanyInfo(): Observable<any>{
        const token = environment.company_token;
        const headers = new HttpHeaders().set('Authorization', token);

        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/CompanyInfo`, { headers })
    }


    getCategories(companyId: number): Observable<any>{
        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/categories?companyId=${companyId}`)
    }

    getProductCategories(companyId: number): Observable<any>{
        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/productcategories?companyId=${companyId}`);
    }


    registerSale(sale:any):Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http.post<any>(`${this.apiUrl}/api/EcommerceApi/ResgisterSale`, sale, { headers })
    }

    createOrder(order:any):Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return this.http.post<any>(`${this.apiUrl}/api/OrdersApi/CreateOrder`, order, { headers })
    }

    getOrders(clientConomeId: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/GetClientOrders`, {
            params: {
                clientConomeId: clientConomeId
            }
        });
    }
    
    checkOut(amount: number, orderId: number, baseUrl: string) {
        return this.http.get(`${this.apiUrl}/api/OrdersApi/Checkout`,{ params: { amount: amount, orderId: orderId, baseUrlUI: baseUrl } });
    }

    sendOrderEmail(orderId: number, userId: number) {
        userId = !userId ? -1 : userId;
        const data = { orderId, userId};
        return this.http.post(`${this.apiUrl}/api/OrdersApi/sendOrderConfirmationEmail`, data);
    }

    getOrderById(id:number): Observable<any>{
        return this.http.get(`${this.apiUrl}/api/OrdersApi/GetOrder/${id}`);
    }

    getAllProducts(companyId: number, page: number = 1, pageSize: number = 20, orderByDate: boolean = false) : Observable<any>{
        let params = new HttpParams()
        .set('companyId', companyId)
        .set("page", page)
        .set('pageSize', pageSize)
        .set('orderByDate', orderByDate);
        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/AllProducts`, { params });
    }

    getProductsByCategoryId(companyId: number, categoryIds: number[], page: number = 1, pageSize: number = 20, orderByDate: boolean = false) {
        let params = new HttpParams()
        .set('companyId', companyId) 
        .set('page', page)
        .set('pageSize', pageSize)
        .set('orderByDate', orderByDate);
        const idsArray = Array.isArray(categoryIds) ? categoryIds : [categoryIds];
        const body = { Ids: idsArray };
        return this.http.post(`${this.apiUrl}/api/EcommerceApi/ProductsByCategoryId`, body, { params });
    }

    getShippingForProduct(companyId: number, productDetailIds: number[], orderTotal: number):Observable<any>{
        const url = `${this.apiUrl}/api/EcommerceApi/ShippingPrice?companyId=${companyId}&orderTotal=${orderTotal}`;
        const body = productDetailIds
        return this.http.post(url, body);
    }

    getTopPicks (companyId: number): Observable<any>{
        return this.http.get<any>(`${this.apiUrl}/api/EcommerceApi/GetTopPicks?companyId=${companyId}`)
    }

    getProductsById(companyId: number, productIds: number[]) {
        let params = new HttpParams()
            .set('companyId', companyId);
            const idsArray = Array.isArray(productIds) ? productIds : [productIds];
        const body = { Ids: idsArray }; 
    
        return this.http.post(`${this.apiUrl}/api/EcommerceApi/ProductsById`, body, { params });
    }
    
    
    getFlashSaleProducts(companyId: number, page: number = 1, pageSize: number = 10, orderByDate: boolean = false,  flashSale : boolean = true, productIds: number[] = []) : Observable<any>{
        let params = new HttpParams()
        .set('companyId', companyId)
        .set("page", page)
        .set('pageSize', pageSize)
        .set('orderByDate', orderByDate)
        .set('flashSale', flashSale);
        let body: any = null;
        if(productIds && productIds.length > 0){
            const idsArray = Array.isArray(productIds) ? productIds : [productIds];
             body = { Ids: idsArray }; 
        } else {
            body =  null
        }
        return this.http.post<any>(`${this.apiUrl}/api/EcommerceApi/FlashSaleProducts`, body, { params });
    }
}
