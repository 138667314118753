import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})

export class BannerComponent {
    @Input() title: string | undefined;
    @Input()
        breadcrumbItems!: Array<{
            link?: string;
            active?: boolean;
            label?: string;
        }>;

    Item!: Array<{
      label?: string;
    }>;

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        this.translateBreadcrumbItems(); // Translate initially
        // Subscribe to language changes and update translations
        this.translateService.onLangChange.subscribe(() => {
            this.translateBreadcrumbItems();
        });
    }

    //gets language for home label
    homeLabel:any
    private async translateBreadcrumbItems() {
        this.homeLabel = await this.translateService.get("menu.HOME").toPromise();
    }

    get currentLanguage(){
        return this.translateService.currentLang;
    }

}




