<div class="modal-content">
        <div class="modal-header bg-secondary">
          <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav nav-tabs card-header-tabs" role="tablist">
            <li class="nav-item" [ngbNavItem]="1">
              <a ngbNavLink class="nav-link fw-medium" href="javascript:void(0);" data-bs-toggle="tab" role="tab"
                aria-selected="true"><i class="ci-unlocked me-2 mt-n1"></i>{{"login-page.LOGIN" | translate}}</a>
              <ng-template ngbNavContent>
    
                <form (ngSubmit)="login(signInForm.value)" [formGroup]="signInForm" class="needs-validation tab-pane fade show" id="signin-tab">

                  <div class="mb-3">
                    <label class="form-label" for="si-email">{{"login-page.EMAIL-ADDRESS" | translate}}</label>
                    <input class="form-control" type="email" id="si-email" placeholder="johndoe@example.com"
                      formControlName="Username" [ngClass]="{ 'is-invalid': submitted && form['Username'].errors }">
                    <div *ngIf="submitted && form['Username'].errors" class="invalid-feedback">
                      <span *ngIf="form['Username'].errors['required']">{{'auth.VALID-EMAIL'|translate}}</span> 
                    </div>
                  </div>

                  <div class="mb-3">
                    
                    <label class="form-label" for="si-password">{{"login-page.PASSWORD" | translate}}</label>

                    <div class="password-toggle">
                      <input class="form-control" [type]="fieldTextType ? 'text' : 'password'" id="si-password"
                        formControlName="password" [ngClass]="{ 'is-invalid': submitted && form['password'].errors }">
                      <div *ngIf="submitted && form['password'].errors" class="invalid-feedback">
                        <span *ngIf="form['password'].errors['required']">{{'auth.VALID-PASSWORD'|translate}}</span> 
                      </div>
                      <label class="password-toggle-btn p-0" aria-label="Show/hide password">
                        <input class="password-toggle-check" type="checkbox"><span class="password-toggle-indicator p-2"
                          [ngClass]="fieldTextType ? 'mdi-eye-off-outline':'mdi-eye-off-outline'"
                          (click)="toggleFieldTextType()"></span>
                      </label>
                    </div>
    
                  </div>
                  <div class="mb-3 d-flex flex-wrap justify-content-between">
                    <div class="form-check mb-2">
                      <input class="form-check-input" type="checkbox" id="si-remember">
                      <label class="form-check-label" for="si-remember">{{'login-page.REMEMBER-ME' | translate}}</label>
                    </div>
                    <button type="button" class="btn bt-sm btn-link" (click)="resetPassword=true">{{"login-page.RESET-PASSWORD" | translate}}</button>
                  </div>
                  
                  <div [ngClass]="labelInfo !== 'info'? 'visible':'invisible'" class="text-primary">
                    {{labelInfo | translate}}
                  </div>

                  <div [ngClass]="labelError !== 'error'? 'visible':'invisible'" class="text-danger">
                    {{labelError| translate}}
                  </div>

                  <button class="btn btn-primary btn-shadow d-block w-100" type="submit">{{"login-page.BUTTON" | translate}}</button>
                </form>
              </ng-template>
            </li>
            <li class="nav-item" [ngbNavItem]="2">
              <a ngbNavLink class="nav-link fw-medium" href="javascript:void(0);" data-bs-toggle="tab" role="tab" aria-selected="false">
                <i class="ci-user me-2 mt-n1"></i>
                {{"sign-up.REGISTER" | translate}}
              </a>
    
              <ng-template ngbNavContent>
                <form 
                  (ngSubmit)="onSubmit()" 
                  [formGroup]="signupForm"
                  class="needs-validation tab-pane fade show"
                  autocomplete="off" 
                  novalidate id="signup-tab"
                >
                 
                  <div class="mb-3">
                    <label class="form-label" for="su-firstname">{{"sign-up.FIRSTNAME" | translate}}</label>
                    <input class="form-control" type="text" id="su-firstname" placeholder="John" formControlName="firstname"
                      [ngClass]="{ 'is-invalid': signupform['firstname'].touched && signupform['firstname'].errors }">
                    <div *ngIf="signupform['firstname'].touched && signupform['firstname'].errors" class="invalid-feedback">
                      <span *ngIf="signupform['firstname'].errors['required']">{{'auth.FILL-FIRSTNAME' |translate}}</span> 
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="su-lastname">{{"sign-up.LASTNAME" | translate}}</label>
                    <input class="form-control" type="text" id="su-lastname" placeholder="Doe" formControlName="lastname"
                      [ngClass]="{ 'is-invalid': signupform['lastname'].touched && signupform['lastname'].errors }">
                    <div *ngIf="signupform['lastname'].touched && signupform['lastname'].errors" class="invalid-feedback">
                      <span *ngIf="signupform['lastname'].errors['required']">{{'auth.FILL-LASTNAME' |translate}}</span> 
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="su-email">{{"login-page.EMAIL-ADDRESS" | translate}}</label>
                    <!-- <input class="form-control" type="email" id="su-email" placeholder="johndoe@example.com" required> -->
                    <input class="form-control" type="email" id="si-email" placeholder="johndoe@example.com"
                      formControlName="email" [ngClass]="{ 'is-invalid': signupsubmit && signupform['email'].errors }"
                      required>
                    <div *ngIf="signupform['email'].touched && signupform['email'].errors" class="invalid-feedback">
                      <span *ngIf="signupform['email'].errors['required']">{{'auth.FILL-EMAIL' |translate}}</span> 
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="su-password">{{"sign-up.PASSWORD" | translate}}</label>
                    <div class="password-toggle">
                      <input class="form-control" [type]="signupPassfield ? 'text' : 'password'" id="su-password" required
                        formControlName="password"
                        [ngClass]="{ 'is-invalid': signupform['password'].touched && signupform['password'].errors }">
                      <label class="password-toggle-btn p-0" [ngClass]="{ 'password-show': signupform['password'].errors }" aria-label="Show/hide password">
                        <input class="password-toggle-check" type="checkbox"><span class="password-toggle-indicator p-2"
                          [ngClass]="{'mdi-eye-off-outline': !signupPassfield, 'mdi-eye-outline': fieldTextType
                      }" (click)="togglesignupPassfield()"></span>
                      </label>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="su-password-confirm">{{"sign-up.CONFIRM-PASSWORD" | translate}}</label>
                    <div class="password-toggle">
                      <input class="form-control" [type]="fieldTextType ? 'text' : 'password'" id="su-password-confirm" required formControlName="confirmPassword"
                      >
                      <label class="password-toggle-btn p-0" aria-label="Show/hide password" >
                        <input class="password-toggle-check" type="checkbox"><span class="password-toggle-indicator p-2" [ngClass]="fieldTextType ? 'mdi-eye-off-outline':'mdi-eye-off-outline'" (click)="toggleFieldTextType()"></span>
                      </label>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="phoneNumber">Phone Number</label>
                    <input class="form-control" type="text" id="phoneNumber" placeholder="000-000-000" formControlName="phoneNumber"
                      [ngClass]="{ 'is-invalid': signupform['phoneNumber'].touched && signupform['phoneNumber'].errors}">
                    <div *ngIf="signupform['phoneNumber'].touched && signupform['phoneNumber'].errors" class="invalid-feedback">
                      <span *ngIf="signupform['phoneNumber'].errors['required']">Please write your phone number</span> 
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="address">Address</label>
                    <input class="form-control" type="text" id="address" placeholder="Tetovo" formControlName="Address"
                      [ngClass]="{ 'is-invalid': signupform['Address'].touched && signupform['Address'].errors}">
                    <div *ngIf="signupform['Address'].touched && signupform['Address'].errors" class="invalid-feedback">
                      <span *ngIf="signupform['Address'].errors['required']">Please write your address</span> 
                    </div>
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="address-number">Address Number</label>
                    <input class="form-control" type="text" id="address-number" placeholder="1230" formControlName="AddressNumber"
                      [ngClass]="{ 'is-invalid': signupform['AddressNumber'].touched && signupform['AddressNumber'].errors }">
                    <div *ngIf="signupform['AddressNumber'].touched && signupform['AddressNumber'].errors" class="invalid-feedback">
                      <span *ngIf="signupform['AddressNumber'].errors['required']">Please fill the address number input</span> 
                    </div>
                  </div>


                  <div class="custom-alert custom-alert-warning" *ngIf="signupForm.hasError('passwordMatch') && signupForm.get('confirmPassword')?.touched">
                    {{"sign-up.PASSWORD-NOT-MATCH" | translate}}
                  </div>

                  <div class="custom-alert custom-alert-warning" *ngIf="signupform['password'].touched && signupform['password'].errors">
                    {{'auth.PASSWORD-REQUIREMENTS'|translate}}
                  </div>

                  <div *ngIf="registrationSucces" class="custom-alert custom-alert-success">{{"sign-up.REGISTRATION-SUCCESSFUL" | translate}}</div>

                  <div class="custom-alert custom-alert-warning" *ngIf="signupForm.controls.email.errors?.required && signupForm.controls.email.touched">{{"sign-up.EMAIL-REQUIRED" | translate}}</div>
                  <div class="custom-alert custom-alert-warning" *ngIf="signupForm.controls.email.errors?.email && signupForm.controls.email.touched">{{"sign-up.INVALID-EMAIL" | translate}}</div>
                  <div class="custom-alert custom-alert-danger" *ngIf="errorMessage"> {{errorMessage | translate}} </div>

                  <button [disabled]="!isFormValid" class="btn btn-primary btn-shadow d-block w-100" type="submit">{{"sign-up.REGISTER" | translate}}</button>
                </form>
              </ng-template>
            </li>
          </ul>
          <button (click)="closemodal()" type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
        </div> 
  <div class="modal-body tab-content py-4">
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>

<div class="resetPasswordModal" *ngIf="resetPassword" tabindex="-1" role="dialog" aria-hidden="true" style="position: absolute;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header justify-content-center">
        <h2 class="text-center mb-4">{{"login-page.WHATS-MY-PASSWORD" | translate}}</h2>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-body">
              <div class="text-center">
                <p>{{"login-page.RESET-PASSWORD" | translate}}</p>
                <div class="panel-body">
                  <fieldset>
                    <form  [formGroup]="forgotPasswordForm">

                    <div class="form-group">
                      <input class="form-control input-lg mb-4"
                        placeholder="{{'login-page.EMAIL-ADDRESS'|translate}}" id="email" type="email"
                        formControlName="Username">
                    </div>
                    </form>
                    <button class="btn btn-lg btn-primary btn-block mx-2" (click)="forgotPassword(forgotPasswordForm.value)">{{'login-page.SENT-MY-PASSWORD'|translate}}</button>
                    <button class="btn btn-lg btn-light btn-block" (click)="resetPassword=false">{{'user-profile.CANCEL'|translate}}</button>

                    <p *ngIf="sendEmailResult.length">{{sendEmailResult}}</p>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
