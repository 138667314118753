import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-payment-service',
    templateUrl: './payment-service.component.html',
})

export class PaymentComponent {
    successUrl = `${environment.backendUrl}/api/payment?isSuccess=true&isMobile=true`;
    failUrl = `${environment.backendUrl}/api/payment?isSuccess=false&isMobile=true`;

    constructor(
        private activatedRoute: ActivatedRoute) { }


    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            if ('paymentForm' in params) {
                this.mobilePayment(params.paymentForm);
            }
        });
    }

    mobilePayment(paymentForm: string){
        const formValues = JSON.parse(paymentForm);
        const form = document.createElement('form');
        form.method = 'post';
        form.action = 'https://www.cpay.com.mk/client/Page/default.aspx?xml_id=/mk-MK/.loginToPay/.simple/';

        // Create input fields with form values
        Object.keys(formValues).forEach((key) => {
            const value = formValues[key];
            const input = document.createElement('input');
            if(key == "successUrl"){
                input.type = 'hidden';
                input.name = "PaymentOKURL";
                input.value = this.successUrl + "&orderId=" + value;
                form.appendChild(input);
            }else if(key == "failUrl"){
                input.type = 'hidden';
                input.name = "PaymentFailURL";
                input.value = this.failUrl + "&orderId=" + value;
                form.appendChild(input);
            }else{
                input.type = 'hidden';
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }
        });

        // Append the form to the document body and submit
        document.body.appendChild(form);
        form.submit();
    }
}