import { CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbCollapseModule, NgbNavModule, NgbDropdownModule, NgbAccordionModule, NgbRatingModule, NgbProgressbarModule, NgbPaginationModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { provideHttpClient, withFetch } from '@angular/common/http';

import { PaginatorComponent } from './components/paginator/paginator.component';
import { ProductsComponent } from './pages/products/products.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { HttpRequestsInterceptor } from './services/interceptor/http-requests.interceptor';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AuthGuardService } from './services/login/auth-guard.service';
import { AuthComponent } from './modals/auth/auth.component';
import { AccountBreadcrumbsComponent } from './components/account-breadcrumbs/account-breadcrumbs.component';
import { CartComponent } from './pages/cart/cart.component';
import { register } from 'swiper/element/bundle';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import {
    MarkdownModule,
    MarkedOptions,
    MarkdownModuleConfig,
    MarkedRenderer }
    from 'ngx-markdown';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { BannerComponent } from './components/banner/banner.component';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { LoaderInterceptor } from './services/interceptor/loader.interceptor';
import { HeaderComponent } from './components/header/header.component';
import { LanguageComponent } from './components/language/language.component';
import { PaymentComponent } from './services/payment-service/payment-service.component';
import { GraphQLModule } from './graphql.module';
import { NgOptimizedImage } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MaterialModule } from './modules/material.module';
import { ForgetPasswordModalComponent } from './components/forget-password-modal/forget-password-modal.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { MyReturnsComponent } from './pages/my-returns/my-returns.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';
import { SwiperDirective } from 'src/lib/swiper.directive';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';

register();
export function HttpLoaderFactory(http:HttpClient){
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


export const MARKED_OPTIONS = new InjectionToken<MarkdownModuleConfig>('MarkedOptions');

export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();
    const linkRenderer = renderer.link;
    renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
    };

    return {
        renderer,
        gfm: true,
        breaks: false,
        pedantic: false,
    };
}


@NgModule({
    declarations: [
        AppComponent,
        PaginatorComponent,
        ProductsComponent,
        AboutusComponent,
        PaginatorComponent,
        CheckoutComponent,
        NavbarComponent,
        UserProfileComponent,
        AuthComponent,
        AccountBreadcrumbsComponent,
        CartComponent,
        ResetPasswordComponent,
        HomeComponent,
        ContactComponent,
        FooterComponent,
        SafeUrlPipe,
        BannerComponent,
        LoaderComponent,
        HeaderComponent,
        LanguageComponent,
        PaymentComponent,
        SignupComponent,
        LoginComponent,
        ForgetPasswordModalComponent,
        MyProfileComponent,
        MyReturnsComponent,
        WishlistComponent,
        ProductDetailsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbCollapseModule,
        NgbNavModule,
        NgbModule,
        NgbDropdownModule,
        NgbRatingModule,
        NgxDropzoneModule,
        NgbProgressbarModule,
        NgbPaginationModule,
        NgbAccordionModule,
        NgbCarouselModule,
        MaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptionsFactory
            }
        }),
        GraphQLModule,
        NgOptimizedImage,
        SwiperDirective
    ],
    providers: [HttpClient,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestsInterceptor,
            multi: true
        },
        {
            provide:HTTP_INTERCEPTORS,
            useClass:LoaderInterceptor,
            multi:true

        },
        AuthGuardService,
        provideHttpClient(withFetch()),
        CookieService,
        provideAnimationsAsync()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
