import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { getUser } from 'src/app/modules/Users';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
const PASSWORD_MIN_LENGTH = 8;
@Component({
    selector: 'app-my-profile',
    standalone: false,
    templateUrl: './my-profile.component.html',
    styleUrl: './my-profile.component.scss'
})
export class MyProfileComponent {

    cardForm!: UntypedFormGroup;
    masterSelected!: boolean;
    submitted = false;
    allnotification: any;
    allpayment: any;
    userID = 0;

    changePasswordForm = new FormGroup({
        NewPassword : new FormControl('', Validators.compose([
            Validators.required,
            Validators.minLength(PASSWORD_MIN_LENGTH),
            this.patternValidator(new RegExp("(?=.*[0-9])"), {
                requiresDigit: true
            }),
            this.patternValidator(new RegExp("(?=.*[A-Z])"), {
                requiresUppercase: true
            }),
            this.patternValidator(new RegExp("(?=.*[a-z])"), {
                requiresLowercase: true
            }),
            this.patternValidator(new RegExp("(?=.*[$#@^!%*?&])"), {
                requiresSpecialChars: true
            })
        ]),
        ),
        ConfirmNewPassword: new FormControl(''),
        token: new FormControl(''),
        userId: new FormControl(''),
    });

    constructor(
        private router: Router,
        private userProfileService: UserProfileService,
        private translate: TranslateService, 
        private cookieService: CookieService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { 
        this.userID = parseInt(this.cookieService.get("userID"), 10);
        
    }

    ngOnInit(): void {
        this.getUser();
        this.clearForm()
    }

    get form() {
        return this.cardForm.controls;
    }

    userId = -1;
    user: any;
    labelError = "error";
    labelSucces = "success";
    labelErrorAddress = "error";
    labelSuccessAdress = "success";
    showCurrentPassword = false;
    showNewPassword = false;
    showConfirmationPassword = false;
    isFormSubmitted = false;
    changePass = {
        currentPassword: "",
        password: "",
        passwordConfirmation: ""
    }

    patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
            // If there's no value, validation is considered valid.
                return null;
            }
      
            const valid = regex.test(control.value);
            return valid ? null : error;
        };
    }
    

    getUser(): void {
        this.userID = parseInt(this.cookieService.get("userID"), 10);
        if (this.userID !== 0) {
            this.userProfileService.getUser(this.userID).subscribe(
                (data: getUser[]) => { 
                    if (data.length > 0) {
                        const userData = data[0]; 
                        this.user = {
                            firstname: userData.name,
                            lastname: userData.surname,
                            id: userData.id,
                            address: userData.address,
                            zip_code: userData.addressNumber,
                            created: userData.created,
                            email: userData.email
                        };
                    }
                },
                (error) => {
                    console.error('Error fetching user data', error);
                }
            );
        } else {
            // Optionally handle the case where userID is 0
            /* this.resetUserData(); */
        }
    }


    /* getUser() {
        const token = this.cookieService.get(LOCAL_STORAGE.TOKEN) || "";
        if (token === "") return;
        this.userId = JSON.parse(atob(token.split('.')[1])).id;
        this.userProfileService
            .getUserById(this.userId)
            .subscribe((user) => {
                this.user = {
                    id: user.id,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    username: user.username,
                    accountdate: user.createdAt,
                    email: user.email,
                    phone_number: user.phone_number,
                    address: user.address,
                    zip_code: user.zip_code,
                    city: user.city,
                    country: user.country,
                    role: user.role.name,
                    createdAt: user.createdAt
                };
            });
    } */
    logout() {
        if(isPlatformBrowser(this.platformId)){
            localStorage.clear();
            /*  this.router.navigate(['posts']); */
        }
    }
   

    changepassword(data:any) {
        this.changePasswordForm.controls['token'].setValue(this.cookieService.get(LOCAL_STORAGE.TOKEN))
        this.changePasswordForm.controls['userId'].setValue(this.userID.toString());


        this.labelError = "";
        this.labelSucces = "";
        this.isFormSubmitted = true;
        if(this.changePasswordForm.valid && (this.changePasswordForm.controls['NewPassword'].value === this.changePasswordForm.controls['ConfirmNewPassword'].value))
        {
            this.userProfileService.changepassword(this.changePasswordForm.value).subscribe({
                next:(res:any) => {
                    if (res.statusCode === 401 && res.message === "Token not verified")
                    {
                        this.labelError = this.translate.instant("user-profile.RESET-PASSWORD-ERROR");
                    }
                    else{
                        this.labelSucces = this.translate.instant("user-profile.RESET-PASSWORD-SUCCESS");
                        this.changePasswordForm.reset();
                    }
                },
                error:(error:any) =>{
                    this.labelError = this.translate.instant("user-profile.RESET-PASSWORD-ERROR");
                }
            });
        }
    }

    toggleCurrentPassword() {
        this.showCurrentPassword = !this.showCurrentPassword;
    }
    toggleNewPassword() {
        this.showNewPassword = !this.showNewPassword;
    }
    toggleConfirmationPassword() {
        this.showConfirmationPassword = !this.showConfirmationPassword;
    }
    clearForm() {
        this.changePass = {
            currentPassword: "",
            password: "",
            passwordConfirmation: ""
        };
        this.labelError = "";
        this.labelSucces = "";
        this.labelErrorAddress = "error";
        this.labelSuccessAdress = "success";
    }
    /* addShippingAddress() {
        this.labelErrorAddress = "error";
        delete this.user.role;
        if (this.user.zip_code.toString().length >= 4) {
            this.userProfileService.saveAddress(this.user, this.userId).subscribe(() => {
                this.labelSuccessAdress = this.translate.instant("user-profile.SHIPPING-DATA-UPDATED");                
                this.getUser();         
            },
            (err) => {
                this.labelErrorAddress = err.error.error.message;
                this.labelSuccessAdress = "success";
            })
        }
        else {
            this.labelErrorAddress = "user-profile.ZIP-CODE-NOT-VALID";
            this.labelSuccessAdress = "success";
        }
    }
    get checkAddressValue() {
        return ((this.user?.phone_number !== "" && this.user?.phone_number !== null && this.user?.address !== "" && this.user?.address !== null && this.user?.zip_code !== "" && this.user?.zip_code !== null && this.user?.city !== "" && this.user?.city !== null && this.user?.country !== "" && this.user?.country !== null));
    } */
}
