<section class="contact-page-sec">
  <app-banner title="{{ 'menu.CONTACT' | translate }}" [breadcrumbItems]="breadCrumbItems"></app-banner>
    <div class="container">
      <div class="row">
        @for (item of contact; track $index) {
        <div class="col-md-4">
          <div class="contact-info">
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <img [src]="item.icon" alt="icon">
              </div>
              <div class="contact-info-text">
                <h2>{{item.title}}</h2>
                <span>{{item.subtitle}}</span> 
              </div>
            </div>            
          </div>          
        </div>  
      }               
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="contact-page-form" method="post">
            <h2>{{contactTitle}}</h2> 
            <form action="contact-mail.php" method="post">
              <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-input-field">
                  <input type="text" [placeholder]=" 'contact.YOUR-NAME' | translate" name="name"/>
                </div>
              </div>  
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-input-field">
                  <input type="email" [placeholder]=" 'contact.EMAIL-ADDRESS' | translate" name="email" required/>
                </div>
              </div>                              
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-input-field">
                  <input type="text" [placeholder]=" 'contact.YOUR-PHONE' | translate" name="phone"/>
                </div>
              </div>  
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-input-field">
                  <input type="text" [placeholder]=" 'contact.SUBJECT' | translate" name="subject"/>
                </div>
              </div>                
              <div class="col-md-12 message-input">
                <div class="single-input-field">
                  <textarea  [placeholder]=" 'contact.MESSAGE' | translate" name="message"></textarea>
                </div>
              </div>                                                
              <div class="single-input-fieldsbtn">
                <input type="submit" [value]="'contact.SEND-MESSAGE' | translate"/>
              </div>                          
            </div>
            </form>   
          </div>      
        </div>
        <div class="col-md-4">        
          <div class="contact-page-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2933.8034567645964!2d20.96826062164688!3d42.01173639561019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1353f1e1cae6d8ab%3A0x320d18699d8d357a!2sLozz%20Toys!5e0!3m2!1sen!2smk!4v1725523821808!5m2!1sen!2smk" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>          
        </div>        
      </div>
    </div>
  </section>