import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Categories, QuestionsCategories } from 'src/app/modules/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    categories: Categories[] = [];
    questionsCategories: QuestionsCategories[]=[];

    constructor(private http: HttpClient) { }

    /* getCategories() {
        this.http.get<{ data: any[], meta: object }>(`${environment.backendUrl}/api/categories`).subscribe(categories => {
            this.categories = categories.data.map(c => { return { id: c.id, ...c.attributes} })
        });
    } */

    getQuestionsCategories(){
        this.http.get<{ data: any[], meta: object }>(`${environment.backendUrl}/api/questions-categories`).subscribe(category=>{
            this.questionsCategories = category.data.map(c=>{ return {id:c.id, ...c.attributes}})
        });
    }
}
