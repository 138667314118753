<div class="px-3 px-sm-4 px-xl-5 pt-1 pb-4 pb-sm-5">
  <!-- No orders message -->
  <div *ngIf="paginatedOrders.length === 0" class="text-center py-5">
    <h5>{{"menu.NO-ORDERS" | translate}}</h5>
    <p>{{"menu.NO-ORDERS-TXT" | translate}}</p>
  </div>

  <!-- Table layout for desktop (above 991px) -->
  <div class="d-none d-lg-block" *ngIf="paginatedOrders.length > 0">
    <table class="table table-hover mb-0">
      <thead class="shadow-lg">
        <tr>
          <th>{{"menu.ORDERS" | translate}} #</th>
          <th>{{"menu.DATA-PURCHASED" | translate}}</th>
          <th>{{"menu.STATUS" | translate}}</th>
          <th>{{"menu.PAYMENT-STATUS" | translate}}</th>
          <th>{{"menu.TOTAL" | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of paginatedOrders">
          <td>{{ order.orderNo }}</td>
          <td>{{ order.orderDate | date:'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>
            <span [ngClass]="getStatusClass(order.orderStatus)" class="badge status-badge">{{ order.orderStatus }}</span>
          </td>
          <td>{{ order.paymentStatus }}</td>
          <td>{{order.total | number: '1.2-2' }} {{ 'currency.CURRENCY_VALUE' | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Card layout for mobile -->
  <div class="d-lg-none" *ngIf="orders.length > 0">
    <div *ngFor="let order of paginatedOrders" class="card modern-order-card mb-4 shadow">
      <div class="card-body p-3">
        <div class="card-header d-flex justify-content-between align-items-center mb-2">
          <div class="order-number-date">
            <h5 class="order-number mb-1">Order #{{ order.orderNo }}</h5>
            <p class="order-date mb-0">{{ order.orderDate | date:'dd/MM/yyyy HH:mm:ss' }}</p>
          </div>
          <div class="order-status">
            <span [ngClass]="getStatusClass(order.orderStatus)" class="badge status-badge">{{ order.orderStatus }}</span>
          </div>
        </div>
        <div class="order-details">
          <div class="detail-item mb-2">
            <strong>{{ 'menu.PAYMENT-STATUS' | translate }}:</strong>
            <span>{{ order.paymentStatus }}</span>
          </div>
          <div class="detail-item">
            <strong>{{ 'menu.TOTAL' | translate }}:</strong>
            <span>{{order.total | number: '1.2-2' }}{{ 'currency.CURRENCY_VALUE' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-paginator 
  *ngIf="paginatedOrders.length > 0"
  [totalItems]="totalItems" 
  [indexPage]="indexPage" 
  [itemsPerPage]="itemsPerPage" 
  (indexPageChange)="onPageChange($event)">
  </app-paginator>
</div>
