import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LOCAL_STORAGE } from 'src/app/modules/constants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private _router: Router, private http: HttpClient, private cookieService: CookieService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.cookieService.get(LOCAL_STORAGE.TOKEN)) {
            return true;
        }
        this._router.navigate(['home']);
        return false;
    }
}
