import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-account-breadcrumbs',
    templateUrl: './account-breadcrumbs.component.html',
    styleUrls: ['./account-breadcrumbs.component.scss']
})
export class AccountBreadcrumbsComponent {

    @Input() username!: string;
    @Input() createdAt!: string;


}
