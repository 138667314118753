import { Component } from '@angular/core';
import { CategoriesService } from './services/categories/categories.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { LOCAL_STORAGE } from './modules/constants';
import { ConomeECommerceService } from './services/conomeServices/conome-e-commerce.service';
import { forkJoin } from 'rxjs';
import { register } from 'swiper/element/bundle';

register();
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    shouldShowNavbar = true;
    selectedLanguage:any;
    languages = ['sq', 'mk', 'en'];
    title = 'lozztoys';
    constructor(
        private categoriesService: CategoriesService,
        private router: Router,
        private cookieService: CookieService,
        private conomeService: ConomeECommerceService
    ) { 
        this.selectedLanguage = this.cookieService.get(LOCAL_STORAGE.SELECTED_LANGUAGE);
        if(this.languages.includes(this.selectedLanguage)){
            this.cookieService.set(LOCAL_STORAGE.SELECTED_LANGUAGE, this.selectedLanguage);
        }
        else {
            this.cookieService.set(LOCAL_STORAGE.SELECTED_LANGUAGE, 'sq');
        }

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                // Check the current route
                const currentRoute = event.url;
                this.shouldShowNavbar = !currentRoute.includes('card-payment');
            }
        });
    }
    productsFromConome: any[] = [];
    companyId: number = -1;
    ngOnInit() {
        this. getCompanyInfo() ;
    }

    getCompanyInfo() {
        this.conomeService.getCompanyInfo().pipe(
            switchMap((res) => {
                if (res && res.settings && res.settings.companyID) {
                this.companyId = res.settings.companyID;
                this.cookieService.set('companyID', this.companyId.toString());
                const allProducts$ = this.conomeService.getAllProducts(this.companyId);
                const categories$ = this.conomeService.getCategories(this.companyId);
                const topPicks$ = this.conomeService.getTopPicks(this.companyId);
                const flashSales$ = this.conomeService.getFlashSaleProducts(this.companyId);
                const newArrivals$ = this.conomeService.getAllProducts(this.companyId, 1, 5, true)
                return forkJoin([allProducts$, categories$, topPicks$, flashSales$, newArrivals$]);
            } else {
                throw new Error('Company ID not found');
            }
            })
        ).subscribe({
            next: ([products, categories, topPicks, flashSales, newArrivals]) => {
                this.conomeService.setProductsData(products.products)
                this.conomeService.setCategoriesData(categories)
                this.conomeService.setTopPicks(topPicks.value.products)
                this.conomeService.setFlashSales(flashSales.products)
                this.conomeService.setNewArrivals(newArrivals.products)
            },
            error: (err) => {
                console.error(err);
            }
        });
    }
}
