import { Component, Inject, PLATFORM_ID, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../../modules/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
@Input()  headerText: string = '';
@Input()  tetxAndLink: any;

constructor(private router: Router){

}
redirectTo(link: string) {
    if (link) {
        if (link.startsWith('http')) {
          window.location.href = link;
        } else {
          this.router.navigate([link]);
        }
      }
  }
}
